import React, {ChangeEvent, MouseEventHandler, useEffect, useRef, useState} from 'react';
import './App.sass';
import logo from "./assets/logo-complete.png";
import steve from "./assets/steve.png";
import steve_lying from "./assets/steve_lying.png";
import Web3 from 'web3';
import TRUABI from "./abi";
import {Contract, ContractOptions} from 'web3-eth-contract';
import tru_placeholder from "./assets/tru-placeholder.png";
import whitelist_logo from "./assets/bubble-whitelist-sale.svg";
import bubble_mint_now from "./assets/bubble-mint-now.svg";
import public_sale_open from "./assets/sign-public-sale-open.svg";

import tru from "./assets/artists/0_TRU/boro-punk-blue-bg.png";

import returnCloseIcon from "./assets/return/icon-error.svg";
import {useWeb3React} from "@web3-react/core";
import {connectors} from "./connectors";
import SelectWalletModal from "./ProvidersModal";

//PROD CONTRACT
//const TRU_CONTRACT_ADDRESS = "0xfAA5018134192eed6Ffc13A862724bFAFa05A8f8";
//TEST CONTRACT
const TRU_CONTRACT_ADDRESS = "0x2CA4C2F416539Aa2dEDF7F2f4fa5a9d907289268";

const API_URL = "/api/";
//LOCAL API
//const API_URL = "http://tru-minting.test/public/api/";
const MAX_ROCKSTARS = 9990;
const PRICE = 66600000000000000;
const minGas = 260000;
const maxGas = 350000;

const DEFAULT_LIMIT_PER_TX = 10;

const CHAINS = {
    1: "Mainnet",
    4: "Rinkeby"
};

const ACCEPTED_CHAIN = 4;

interface Artist {
    visible: boolean;
    name: string;
    photo: string;
    mints: number;
    text?: string;
}

const initialArtist: Artist = {
    visible: true,
    name: "The Rocking Uniquehorns",
    photo: tru,
    mints: 0
};

interface Bubble {
    level: "success"|"warning"|"error";
    header: string;
    description: string;
}

interface MintedItem {
    videoUrl?: string;
    mainImage?: string;
    ready: boolean;
    refundable: boolean;
    tokenId: number;
}

enum MintMode {
    Whitelist,
    Pause,
    Public
}

function getMintMode(): MintMode {
    const now = new Date().getTime(),
        whitelistMintEnd = new Date("2021-11-17T11:00:00+00:00").getTime(),
        publicMintStart = new Date("2021-11-17T18:00:00+00:00").getTime();
    if (now < whitelistMintEnd) return MintMode.Whitelist;
    if (now < publicMintStart) return MintMode.Pause;
    return MintMode.Public;
}

type Status = "initializing"|"unavailable"|"notConnected"|"connecting"|"connected";

function App() {
    const [artists, setArtists] = useState<Artist[]|undefined>(undefined);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [searchArtists, setSearchArtists] = useState<Artist[]|undefined>(undefined);
    const [selectedArtist, setSelectedArtist] = useState<Artist>(initialArtist);
    const [artistSelectionOpen, setArtistSelectionOpen] = useState<boolean>(false);
    const artistMenuRef = useRef<HTMLDivElement>(null);

    const [limitPerTx, setLimitPerTx] = useState<number>(DEFAULT_LIMIT_PER_TX);
    const [pageState, setPageState] = useState<""|"success"|"error">("");
    const [minted, setMinted] = useState<number>(0);
    const [isSoldOut, setSoldOut] = useState<boolean>(false);
    const [price, setPrice] = useState<string>("...");

    const [ethereum, setEthereum] = useState<any>(null);

    const [status, setStatus] = useState<Status>("notConnected");

    const [isProvidersModalOpen, setIsProvidersModalOpen] = useState<boolean>(false);

    const [mintedCnt, setMintedCnt] = useState<number>(0);
    const {
        library,
        chainId,
        account,
        activate,
        deactivate,
        active
    } = useWeb3React();

    useEffect(()=>{
        if (!library) return;
        if (active) {
            setStatus("connected");
            setEthereum(library.provider);
        } else {
            setStatus("notConnected");
            setEthereum(null);
        }
        if (chainId !== ACCEPTED_CHAIN) {
            switchNetwork(ACCEPTED_CHAIN);
        }
    }, [library, active, account, chainId]);

    useEffect(()=>{
        tryUseSavedProvider();
    }, []);

    const tryUseSavedProvider = () => {
        const provider = window.localStorage.getItem("provider");
        if (!provider) return;
        // @ts-ignore
        activate(connectors[provider]);
    };

    const saveProvider = (name: string) => {
        window.localStorage.setItem("provider", name);
    };

    const toHex = (num: number) => {
        const val = Number(num);
        return "0x" + val.toString(16);
    };

    const switchNetwork = async (network: number) => {
        await library.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: toHex(network) }]
        });
    };

    const connect = () => {
        setIsProvidersModalOpen(true);
    };

    const selectProvider = async (provider: string) => {
        saveProvider(provider);
        // @ts-ignore
        const providerConn = connectors[provider];
        await activate(providerConn);
    };

    const currentEnv = chainId === 1 ? "https://etherscan.io/" : "https://rinkeby.etherscan.io/";

    const [initialized, setInitialized] = useState<boolean>(false);

    const [chainIdError, setChainIdError] = useState<string>("");

    const [web3, setWeb3] = useState<Web3|undefined>(undefined);
    const [contract, setContract] = useState<Contract|undefined>(undefined);

    const [statusInfo, setStatusInfo] = useState<string>("");

    const [mintingInProgress, setMintingInProgress] = useState<boolean>(false);

    const [mintValue, setMintValue] = useState<string>("1");

    const [inputError, setInputError] = useState<string>("");

    const [bubble, setBubble] = useState<Bubble|undefined>(undefined);

    const [mintMode, setMintMode] = useState<MintMode>(getMintMode());

    const [isReturnAvailable, setIsReturnAvailable] = useState<boolean>(false);
    const [returnMode, setReturnMode] = useState<boolean>(false);
    const [trusToBeReturned, setTrusToBeReturned] = useState<number[]>([]);
    const [returnConfirmation, setReturnConfirmation] = useState<boolean>(false);

    const minItems = 3;

    const extendMintedWithPlaceholders = (minted: MintedItem[]): (MintedItem|undefined)[] => {
        if (minted.length >= minItems) return minted;
        return [
            ...minted,
            ...Array.from(Array(minItems-minted.length))
        ];
    };

    const [mintedItems, setMintedItems] = useState<(MintedItem|undefined)[]>(
        extendMintedWithPlaceholders([])
    );

    const loadArtists = async () => {
        try {
            const artists = await fetch(API_URL).then(res => res.json());
            setArtists(artists);

        } catch (e) {
            console.error(e);
        }
    };

    const selectArtistFromUrl = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions,no-restricted-globals
        const pathname = decodeURIComponent(location.pathname.substring(1)).toLowerCase().replace(/\s/g, "");
        loadAndSelectArtist(pathname);
    };

    const selectArtistByName = (name: String) => {
        loadAndSelectArtist(name);
    };

    const loadAndSelectArtist = async (name: String|undefined) => {
        name = name ? name : "currentperformer";
        const remoteArtist : Artist = await fetch(API_URL + "index.php?artist=" + name).then(res => res.json());
        const artist = remoteArtist.name ? remoteArtist : await fetch(API_URL + "index.php?artist=therockinguniquehorns").then(res => res.json());
        setSelectedArtist(artist);
    };

    const selectArtist = (artist: Artist) => {
        setArtistSelectionOpen(false);
        setSelectedArtist(artist);
        setSearchTerm("");
    };

    const makeArtistPick = async (artist: string, account: string, cnt: number, transactionHash: string) => {
        try {
            await fetch(API_URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                    "addr": account,
                    "cnt": cnt.toString(),
                    "artist": artist,
                    "transaction_hash": transactionHash
                })
            });
            await loadArtists();
        } catch (e) {
            console.error(e);
        }
    };

    const notifyTransactionConfirmed = async (transactionHash: string) => {
        try {
            await fetch(API_URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                    "confirmed": "1",
                    "transaction_hash": transactionHash
                })
            });
            await loadArtists();
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        setInitialized(false);
        if (!ethereum) return;
        const w3 = new Web3(ethereum);
        setWeb3(w3);

        let refreshTO: number|undefined;
        (async () => {
            // @ts-ignore
            const contract = new w3.eth.Contract(TRUABI, TRU_CONTRACT_ADDRESS);
            // @ts-ignore
            setContract(contract);

            const refresh = async () => {
                const nftSupply = await contract.methods.totalSupply().call();
                setMinted(nftSupply);

                const price = w3.utils.fromWei(await contract.methods.getPrice().call(), 'ether');
                setPrice(price);

                try {
                    const RETURN_PERIOD_START = Number(await contract.methods.RETURN_PERIOD_START().call()),
                        RETURN_PERIOD_END = Number(await contract.methods.RETURN_PERIOD_END().call());
                    const now = new Date().getTime()/1000;
                    setIsReturnAvailable(
                        now >= RETURN_PERIOD_START && now <= RETURN_PERIOD_END
                    );
                } catch {}

                setInitialized(true);
                try {
                    let soldOut = await contract.methods.isSoldOut().call();
                    if (soldOut) {
                        setSoldOut(true);
                        setBubble({
                            level: "success",
                            description: "",
                            header: "SOLD OUT"
                        });
                    }
                }
                catch (e) {
                    console.log(e);
                }
            };
            refresh();
            refreshTO = setInterval(()=>refresh(), 30000) as any as number;
        })();

        return () => {
            if (refreshTO) {
                clearInterval(refreshTO);
            }
        }
    }, [chainId, ethereum]);

    const refresh = async () => {
        if (mintMode == MintMode.Whitelist) {
            const isInWhiteList = await contract!.methods.isWalletWhitelisted(account).call();
            if (!isInWhiteList) {
                setBubble({
                    header: "Sorry... You can not mint",
                    description: "At the moment minting is only open to users on our Whitelist...",
                    level: "warning"
                });
            }
        }

        try {
            const limitRes = await contract!.methods.getMaxMintableTransactionForWallet(account).call();
            const limit = Number(limitRes);
            //there's a -1 in contract so we need to re-add it for display purposes
            if (!isNaN(limit)) setLimitPerTx(limit + 1);
        } catch (e) {
            setLimitPerTx(DEFAULT_LIMIT_PER_TX);
        }

        const trus = await contract!.methods.bandOfOwner(account).call();
        Promise.all(
            trus.map((tru: string) =>
                Promise.all([
                    contract!.methods.tokenURI(tru).call(),
                    contract!.methods.isEligibleForRefund(tru).call(),
                ])
                    .then(([uri, isEligibleForRefund]: [string, boolean]) => {
                        return new Promise((resolve, reject) => {
                            fetch(uri)
                                .then(res=>res.json())
                                .then(data=>resolve({
                                    ...data,
                                    isEligibleForRefund: isEligibleForRefund
                                }))
                                .catch(()=>resolve(undefined))
                        });
                    })
            )
        ).then(items => {
            setMintedItems(
                extendMintedWithPlaceholders(
                    items.map(data => {
                        if (!data) return {ready: false} as MintedItem;
                        var animation = (data as any).animation_url
                        var image = (data as any).image
                        return {
                            ready: true,
                            videoUrl: "https://stairwaytoheaven.mypinata.cloud/ipfs/" + animation.substring(7),
                            mainImage: "https://stairwaytoheaven.mypinata.cloud/ipfs/" + image.substring(7),
                            refundable: (data as any).isEligibleForRefund,
                            tokenId: (data as any).tokenId
                        } as MintedItem;
                    })
                )
            );
        });
    };

    useEffect(()=>{
        setChainIdError("");
        setLimitPerTx(DEFAULT_LIMIT_PER_TX);

        let refreshTO: number|undefined;
        if (status === "connected") {
            if (chainId !== ACCEPTED_CHAIN) {
                setChainIdError(`Wrong Network detected. Please select ${CHAINS[ACCEPTED_CHAIN]} in your wallet`);
                return;
            }
            if (contract) {
                refresh();
                refreshTO = setInterval(()=>refresh(), 30000) as any as number;
            }
        }
        return () => {
            if (refreshTO) {
                clearInterval(refreshTO);
            }
        };
    }, [mintMode, contract, chainId, status, account]);

    useEffect(()=>{
        //selectArtistByName("vogelhaus");
        const to = setInterval(()=>{
            setMintMode(getMintMode());
        }, 1000);
        loadArtists();

        const to2 = setInterval(()=>{
            loadArtists();
        }, 20000);

        selectArtistFromUrl();
        return () => {
            clearInterval(to);
            //clearInterval(to2);
        };
    }, []);

    useEffect(()=>{
        if (!artistSelectionOpen) return;
        const handler = (event: MouseEvent) => {
            if (!artistMenuRef.current) return;

            let target = event.target as HTMLElement|null;
            while (target && target !== artistMenuRef.current) {
                target = target.parentElement;
            }
            if (!target) {
                close();
            }
        };
        const escHandler = (event: KeyboardEvent) => {
            if (event.key == "Escape") {
                close();
            }
        };
        const close = () => {
            setArtistSelectionOpen(false);
            setSearchTerm("");
        };
        document.addEventListener("click", handler);
        document.addEventListener("keyup", escHandler);
        return () => {
            document.removeEventListener("click", handler);
            document.removeEventListener("keyup", escHandler);
        };
    }, [artistSelectionOpen]);

    useEffect(()=>{
        const list = (artists || []).filter(
            artist=>artist.name
                .replace(/\s/g, "")
                .toLowerCase()
                .includes(
                    searchTerm.replace(/\s/g, "").toLowerCase()
                )
        );
        setSearchArtists(list);
    }, [artists, searchTerm]);

    const onSearchTermKeyup = (ev: ChangeEvent) => {
        setSearchTerm((ev.target as any).value);
    };

    const mint = () => {
        if (!initialized || !contract || mintingInProgress) return;
        if (status !== "connected") {
            setStatusInfo("Please connect wallet");
            return;
        }
        setStatusInfo("");
        const amount = Number(mintValue) >> 0;
        if (amount > limitPerTx || amount < 1) {
            setStatusInfo("Invalid mint value");
            return;
        }
        setBubble({
            level: "success",
            header: "Minting...",
            description: "Please proceed in wallet"
        });
        setMintingInProgress(true);
        (async () => {
            var gl = (maxGas * amount);

            const mintFunction = mintMode==MintMode.Whitelist?contract.methods.mintWhitelist:contract.methods.mint;

            try {
                const egl = await mintFunction(amount).estimateGas({
                    from: account,
                    to: TRU_CONTRACT_ADDRESS,
                    value: PRICE * amount
                });
                console.log("Gas Limit estimate: " + egl);
                gl = egl > 0 && egl < (maxGas * amount) ? egl : (maxGas * amount);
                console.log("Gas limit set after sanity check: " + gl);
            }
            catch(err) {
                console.log(err);
            };

            const trInfo: any = {};

            mintFunction(amount)
                .send({
                    gasLimit: gl,
                    from: account,
                    to: TRU_CONTRACT_ADDRESS,
                    value: PRICE * amount,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null
                })
                .on('error', function (error: any) {
                    console.log("on error");
                    console.log(error);
                    setMintingInProgress(false);

                    if(error.code !== 4001) {
                        setStatusInfo(`Error occured: ${error.message}`);
                    }
                })
                .on('transactionHash', function (transactionHash: string) {
                    console.log("on transactionHash");
                    console.log(transactionHash);

                    trInfo["hash"] = transactionHash;

                    makeArtistPick(selectedArtist.name, account!, amount, transactionHash);
                    setBubble({
                        level: "success",
                        header: "Transaction waiting to be confirmed",
                        description: `<a target="_blank" href="${currentEnv}/tx/${transactionHash}" title="View ${transactionHash}">Open on Etherscan</a>. Do not close window until minting is finished.`
                    });
                    setStatusInfo(`Transaction waiting to be confirmed. <a target="_blank" href="${currentEnv}/tx/${transactionHash}" title="View ${transactionHash}">Open on Etherscan</a>`)
                })
                .on('receipt', function (receipt: any) {
                    console.log("on receipt");
                    console.log(receipt);
                })
                .on('confirmation', function (confirmationNumber: any, receipt: any) {
                    console.log(confirmationNumber, receipt);
                })
                .then((res: any) => {
                    console.log(res);
                    setMintedCnt(amount);
                    setStatusInfo("");
                    setPageState("success");
                    setBubble(undefined);
                    setMintingInProgress(false);

                    notifyTransactionConfirmed(trInfo["hash"]);
                })
                .catch((err: any) => {
                    console.log(err);
                    setStatusInfo("");
                    setPageState("");
                    setBubble(undefined);
                    setMintingInProgress(false);
                });
        })();
    };

    const getInputError = (value: string) => {
        if (value === "") return "";

        const val = Number(value);
        let inputErr = "";
        if (isNaN(val)) {
            inputErr = "Invalid input 1";
        } else {
            if (val < 1 || val > limitPerTx) {
                inputErr = "Invalid input 2";
            }
        }
        return inputErr;
    };

    const onMintValueChange = (event: ChangeEvent) => {
        setInputError("");

        let value = (event.target as any).value;
        setMintValue(value);

        setTimeout(()=>{
            const err = getInputError(value);
            if (err) {
                setInputError(err);
            }
        }, 500);
    };

    const goBack = () => {
        setPageState("");
        window.location.reload();
    };

    const closeBubble = () => {
        setBubble(undefined);
    };

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const startTruReturn = () => {
        setReturnMode(true);
    };

    const cancelTruReturn = () => {
        setReturnMode(false);
        setReturnConfirmation(false);
        setTrusToBeReturned([]);
    };

    const triggerTruReturnItem = (itemIndex: number) => {
        const ind = trusToBeReturned.indexOf(itemIndex);
        if (ind < 0) {
            setTrusToBeReturned([...trusToBeReturned, itemIndex]);
        } else {
            trusToBeReturned.splice(ind, 1);
            setTrusToBeReturned([...trusToBeReturned]);
        }
    };

    const proceedWithTruReturn = () => {
        if (trusToBeReturned.length == 0) return;
        setReturnConfirmation(true);
        scrollToTop();
    };

    const returnTrus = () => {
        if (trusToBeReturned.length == 0) return;

        setBubble({
            level: "success",
            header: "Returning...",
            description: "Please proceed in wallet"
        });
        setReturnConfirmation(false);
        (async ()=>{
            let amount = trusToBeReturned.length;
            var gl = (maxGas * amount);

            try {
                const egl = await contract!.methods.fire(trusToBeReturned).estimateGas({
                    from: account,
                    to: TRU_CONTRACT_ADDRESS
                });
                console.log("Fire Gas Limit estimate: " + egl);
                gl = egl > 0 && egl < (maxGas * amount) ? egl : (maxGas * amount);
                console.log("Fire Gas limit set after sanity check: " + gl);
            }
            catch(err) {
                console.log(err);
            };

            await contract!.methods.fire(trusToBeReturned)
                .send({
                    from: account,
                    gasLimit: gl,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null
                })
                .on('error', function (error: any) {
                    console.log("on error");
                    console.log(error);

                    if(error.code !== 4001) {
                        setStatusInfo(`Error occured: ${error.message}`);
                    }
                })
                .on('transactionHash', function (transactionHash: string) {
                    console.log("on transactionHash");
                    console.log(transactionHash);
                    setBubble({
                        level: "success",
                        header: "Transaction waiting to be confirmed",
                        description: `<a target="_blank" href="${currentEnv}/tx/${transactionHash}" title="View ${transactionHash}">Open on Etherscan</a>.`
                    });
                })
                .on('receipt', function (receipt: any) {
                    console.log("on receipt");
                    console.log(receipt);
                })
                .on('confirmation', function (confirmationNumber: any, receipt: any) {
                    console.log(confirmationNumber, receipt);
                })
                .then((res: any) => {
                    console.log(res);
                    setStatusInfo("");
                    setBubble(undefined);
                    cancelTruReturn();
                    refresh();
                })
                .catch((err: any) => {
                    setStatusInfo("");
                    setBubble(undefined);
                    cancelTruReturn();
                });
        })();
    };

    const shortAccountId = (!account || account.length < 10) ? account :
        account.substring(0, 5) + '...' + account.substring(account.length-4, account.length);

    const page =
        <div className={"container "+pageState}>
            {returnConfirmation&&<div className="return-confirmation-wrapper">
                <div className="return-confirmation">
                    <div className="return-confirmation-content">
                        <div className="close" onClick={cancelTruReturn}/>
                        <h2>Do you really want to return your TRUs?</h2>
                        <div className="text">
                            This action can not be reverted. <br/>
                            Gas fees are non refundable.
                        </div>
                        <div className="button-container">
                            <button className="button dark-red" onClick={returnTrus}>
                                RETURN TRUs <span className="icon arrow-right" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
            <div className={"top-container-wrapper " + (mintMode==MintMode.Pause?"paused":"")}>
                <div className="top-container">
                    {mintMode==MintMode.Pause&&<div className="link">
                        <a href="https://rockinguniquehorns.com/">
                            <span className="icon arrow-left"/>
                            BACK TO MAINPAGE
                        </a>
                    </div>}

                    <div className="logo">
                        <a href="https://rockinguniquehorns.com/" target="_blank">
                            <img src={logo}/></a>
                    </div>

                    {pageState==""&&<div className="status-container">
                        {status=="initializing"&&
                        <div>
                            <button className="button red">
                                Synchronisation with wallet ongoing...
                            </button>
                        </div>}
                        {status=="unavailable"&&
                        <div>
                            <button className="button red">
                                Wallet not available :(
                            </button>
                        </div>}
                        {status=="notConnected"&&
                        <div>
                            <button className="button red" onClick={connect}>
                                CONNECT
                                <span className="icon arrow-right"/>
                            </button>
                        </div>}
                        {status=="connecting"&&
                        <div>
                            <button className="button red">
                                CONNECTING...
                            </button>
                        </div>}
                        {status=="connected"&&
                        <div>
                            <button className="button red">
                                Connected account: {shortAccountId}
                            </button>
                        </div>}
                    </div>}
                </div>
            </div>
            {pageState==""&&<div className="content-container">
                {mintMode==MintMode.Pause&&<div className="paused">
                    <div className="overlay"></div>
                    <div className="bubble-container">
                        <div className={"bubble success"}>
                            {/*<div className="close" onClick={closeBubble} />*/}
                            <div className="icon" />
                            <div className="header">Whitelist Sale finished</div>
                            <div className="description">
                                Preparing Public Sale, start at 6pm UTC
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="form">
                <div className="steve desktop">
                    <div className={"whitelist-wrapper "+((mintMode==MintMode.Pause||bubble)?"no-label":"")}>
                        <a className="whitelist" href="https://discord.gg/zW59zzGggS" target="_blank">
                            <img src={mintMode==MintMode.Whitelist?whitelist_logo:bubble_mint_now} />
                        </a>
                    </div>
                    <img src={steve} />
                </div>
                <div className="info">

                    <h2>Mint your TRU NFT</h2>

                    {selectedArtist.visible&&<div className="text-label">
                        50% of the minting price will go to:
                    </div>}

                    {selectedArtist.visible&&<div className="supported-artist-block">
                        <div className="photo-col">
                            <img src={selectedArtist.photo} />
                        </div>
                        <div className="name-col">
                            <div className="name">
                                {selectedArtist.name}
                            </div>
                            {artists&&<div className="mints">
                                <span>{selectedArtist.mints}</span>
                                {selectedArtist.mints===1?"Mint":"Mints"}
                            </div>}
                        </div>
                        {artists&&<div className="button-col">
                            <button className="button" onClick={()=>setArtistSelectionOpen(true)}>
                                [Change]
                            </button>
                        </div>}

                        {artists&&artistSelectionOpen&&<div className="artist-selection-dropdown" ref={artistMenuRef}>
                            <div className="input-container">
                                <input type="text"
                                       value={searchTerm}
                                       onChange={onSearchTermKeyup}
                                       placeholder={"Search"}
                                />
                            </div>
                            {(searchArtists||[]).map((artist, i) => {
                                return <div key={i} className="artist" onClick={()=>selectArtist(artist)}>
                                    <div className="photo">
                                        <img src={artist.photo} />
                                    </div>
                                    <div className="name">
                                        {artist.name}
                                    </div>
                                    <div className="mints">
                                        <div>{artist.mints}</div>
                                        {artist.mints===1?"Mint":"Mints"}
                                    </div>
                                </div>;
                            })}
                        </div>}
                    </div>}

                    <div className="text-label">
                        Each TRU NFT <span>{!initialized?"...":price} ETH</span>,
                        transaction <span>limit of {limitPerTx}</span>.
                    </div>

                    {!isSoldOut&&<div className="mint-container">
                        <div className="input-container">
                            <input type="number"
                                   inputMode="numeric"
                                   pattern="[0-9]*"
                                   min="1"
                                   max="10"
                                   placeholder="Number"
                                   value={mintValue}
                                   onChange={onMintValueChange}
                            />
                        </div>
                        {chainIdError&&<div>{chainIdError}</div>}
                        {!chainIdError&&<>
                            {status=="connected"&&<button className="button green" onClick={mint}>
                                MINT NOW
                                <span className="icon arrow-right"/>
                            </button>}
                            {status!="connected"&&<button className="button green" onClick={connect}>
                                CONNECT
                                <span className="icon arrow-right"/>
                            </button>}
                        </>}
                    </div>}
                    {isSoldOut&&<div className="mint-container">
                        SOLD OUT
                    </div>}

                    {bubble&&<div className={"bubble " + bubble.level}>
                        {/*<div className="close" onClick={closeBubble} />*/}
                        <div className="icon" />
                        <div className="header">{bubble.header}</div>
                        <div className="description" dangerouslySetInnerHTML={{__html: bubble.description}}/>
                    </div>}

                    <div className="status-container">
                        {!initialized&&<>
                            <div>
                                Initializing...
                            </div>
                        </>}
                        {initialized&&<>
                            {inputError&&<div>{inputError}</div>}
                            {!inputError&&<div dangerouslySetInnerHTML={{__html:statusInfo}} />}
                        </>}
                    </div>
                </div>
                <div className="steve mobile">
                    <div className={"whitelist-wrapper "+((mintMode==MintMode.Pause||bubble)?"no-label":"")}>
                        <a className="whitelist" href="https://discord.gg/zW59zzGggS" target="_blank">
                            <img src={mintMode==MintMode.Whitelist?whitelist_logo:bubble_mint_now} />
                        </a>
                    </div>
                    <img src={steve} />
                </div>
            </div>
            <div className="minted-items-container">
                <div className="minted-items-header">
                    <h2>Your Band of Rocking Uniquehorns</h2>
                    {/*todo remove true*/}
                    {(isReturnAvailable||true)&&<div className="return-button" onClick={startTruReturn}>
                        <span>Return TRUs</span>
                    </div>}
                </div>
                <div className="items">
                    {mintedItems.map((item, i) => {
                        return <div key={i} className="item">
                            {!item&&<div className="placeholder">
                                <img src={tru_placeholder} />
                            </div>}
                            {item&&!item.ready&&<div className="placeholder loading">
                                <img src={tru_placeholder} />
                                <div className="label">
                                    Revealing...
                                </div>
                            </div> }
                            {item&&item.ready&&<div>
                                <div className="video-wrapper">
                                    {returnMode && trusToBeReturned.includes(item.tokenId) &&
                                        <div className="return-layout">
                                            <img src={returnCloseIcon} />
                                        </div>
                                    }
                                    <video controls loop>
                                        <source src={item?.videoUrl}
                                                type="video/mp4" />
                                    </video>
                                </div>
                                {/*todo add item?.refundable*/}
                                {returnMode && item.refundable &&<div className="select-member" onClick={()=>triggerTruReturnItem(item?.tokenId)}>
                                    <span>Select Bandmember</span>
                                    <span className={"select-box " + (trusToBeReturned.includes(item.tokenId)?"selected":"")} />
                                </div>}
                            </div>}
                        </div>;
                    })}
                </div>
                {returnMode&&<div className="return-buttons">
                    <button className="button green" onClick={cancelTruReturn}>
                        <span className="icon arrow-left" />
                        KEEP UNIQUEHORNS
                    </button>
                    <button className="button red" onClick={proceedWithTruReturn}>
                        GOODBYE TRUs
                        <span className="icon arrow-right" />
                    </button>
                </div>}
            </div>
            {artists&&<div className="backed-artists-container">
                <h3>Backed Artists</h3>
                <div className="artists">
                    {artists.map((artist, i) => {
                        return <div key={i} className="artist" onClick={()=>{selectArtist(artist);scrollToTop();}}>
                            <div className="photo">
                                <img src={artist.photo} />
                            </div>
                            <div className="name">
                                {artist.name}
                            </div>
                            <div className="mints">
                                <div>{artist.mints}</div>
                                {artist.mints===1?"Mint":"Mints"}
                            </div>
                        </div>;
                    })}
                </div>
            </div>}
            </div>}
            {pageState!=""&&<div className="result">
                <div className="steve">
                    <img src={pageState=="success"?steve:steve_lying} />
                </div>
                <div className="content">
                    {pageState=="success"&&<>
                        <h2 className="green">Congrats!</h2>
                        <h2>You just minted {mintedCnt} TRUs!</h2>
                        <div className="comment">
                            Welcome to the<br/>
                            band! 🎸🤘
                        </div>
                        <div>
                            <button className="button green icon-left" onClick={goBack}>
                                <span className="icon arrow-left"/>
                                BACK
                            </button>
                        </div>
                    </>}
                    {pageState=="error"&&<>
                        <h2 className="red">We're sorry...</h2>
                        <h2>You can not mint<br/>any more TRUs</h2>
                        <div>
                            <button className="button red icon-left" onClick={goBack}>
                                <span className="icon arrow-left"/>
                                BACK
                            </button>
                        </div>
                    </>}
                </div>
            </div>}
        </div>;

    return <>
        <SelectWalletModal
            closeModal={()=>setIsProvidersModalOpen(false)}
            isOpen={isProvidersModalOpen}
            onProviderSelected={selectProvider}
        />
        {page}
    </>;
}

export default App;
