import provider_cbw from "./assets/providers/cbw.png";
import provider_mm from "./assets/providers/mm.png";
import provider_wc from "./assets/providers/wc.png";
import "./ProvidersModal.sass";
import {useEffect, useState} from "react";


export default function SelectWalletModal(
    {
        isOpen,
        closeModal,
        onProviderSelected
    } :
    {
        isOpen: boolean,
        closeModal: () => void,
        onProviderSelected: (name: string)=>void
    }
) {
    const [show, setShow] = useState<boolean>(false);
    const setProvider = (type: string) => {
        onProviderSelected(type);
        close();
    };
    const close = () => {
        setShow(false);
        setTimeout(()=>{
            closeModal();
        }, 300);
    };
    useEffect(()=>{
        if (isOpen) {
            setTimeout(()=>setShow(true), 10);
        }
    }, [isOpen]);

    const providers = [
        {
            image: provider_cbw,
            name: "coinbaseWallet",
            title: "Coinbase Wallet",
        },
        {
            image: provider_wc,
            name: "walletConnect",
            title: "Wallet Connect",
        },
        {
            image: provider_mm,
            name: "injected",
            title: "Metamask",
        }
    ];

    return <>
        {isOpen && <div className={"modal-container " + (show?"show":"")}>
            <div className="modal-bg"/>
            <div className="modal">
                <div className="modal-content">
                    <h2>Select wallet</h2>
                    <div className="close" onClick={close}>
                        <svg viewBox="0 0 24 24" focusable="false" aria-hidden="true">
                            <path fill="currentColor"
                                  d="M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z"></path>
                        </svg>
                    </div>
                    <div className="providers">
                        {providers.map(provider=>
                            <div className="provider"
                                 key={provider.name}
                                 onClick={()=>setProvider(provider.name)}>
                                <img src={provider.image}/>
                                <label>{provider.title}</label>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        }
    </>;
}
